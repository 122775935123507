import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { appManagerActions } from '../../store/appManager/actions';
import { accountTabsArray } from './../Account/AccountTabs/accountTabsArray';

import useAuth from '../../App/hooks/useAuth';
import { modalsActions } from '../../store/modals/actions';

import './styles/UserAccountTab.scss';

function UserAccountTab() {
  const history = useHistory();
  const { isManager } = useAuth();

  const dispatch = useDispatch();

  const handleClick = (index) => {
    dispatch(appManagerActions.selectedAccountTab.set(index));
    dispatch(modalsActions.profileMenu.close());
    history.push('/mon-compte');
  };

  return (
    <ul className="user-account-tab">
      {accountTabsArray
        .filter((elmt) => (isManager ? true : !elmt.onlyAdmin))
        .map((elmt) => (
          <li
            key={elmt.index}
            className="user-account-tab-elt"
            onClick={() => handleClick(elmt.index)}
          >
            {elmt.name}
          </li>
        ))}
    </ul>
  );
}

export default UserAccountTab;
