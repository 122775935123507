import { useAppDispatch } from '../../../../App/redux/store';
import Button from '../../../../components/Assets/Button';
import RefreshIcon from '../../../../shared/components/svgIcons/RefreshIcon';
import fetchActiveResearchesThunk from '../../services/thunks/fetchActiveResearchesThunk';
import styles from './refreshActiveResearchesButton.module.scss';
function RefreshActiveResearchesButton() {
  const dispatch = useAppDispatch();

  const handleRefreshActiveResearches = () => {
    dispatch(fetchActiveResearchesThunk({ loadAdsCount: true }));
  };

  const ButtonContent = () => {
    return (
      <div className={styles.buttonContent}>
        <RefreshIcon /> <span>RAFRAICHIR</span>
      </div>
    );
  };
  return (
    <Button
      btnType="button"
      btnContent={<ButtonContent />}
      handleClick={handleRefreshActiveResearches}
      primary
    />
  );
}

export default RefreshActiveResearchesButton;
