import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import { authActions } from '../../store/auth/actions';
import { passwordResetSchema } from './constants';

import useQuery from '../../utils/useQuery';
import Button from '../Assets/Button';
import TextInput from '../Forms/TextInput';

interface IPasswordResetFormProps {
  path: string;
}

function PasswordResetForm({ path }: IPasswordResetFormProps) {
  const history = useHistory();
  const dispatch = useDispatch();
  const query = useQuery();

  if (!query.get('token')) {
    return <Redirect to={{ pathname: '/inscription' }} />;
  }

  const formik = useFormik({
    initialValues: {
      username: query.get('username'),
      password: '',
      token: query.get('token'),
    },
    validationSchema: passwordResetSchema,
    onSubmit: (values) => {
      if (path === '/premier-mot-de-passe') {
        dispatch(authActions.firstPassword.request({ values, history }));
      }
      if (path === '/recuperation-mot-de-passe') {
        dispatch(authActions.passwordReset.request({ values, history }));
      }
    },
  });

  const { values, touched, errors } = formik;

  return (
    <form className="password-form" noValidate>
      <TextInput
        name="username"
        label="Email"
        type="email"
        value={values.username}
        handleChange={formik.handleChange}
        error={touched.username && Boolean(errors.username)}
        helperText={errors.username}
      />
      <TextInput
        name="password"
        label="Mot de passe"
        type="password"
        value={values.password}
        handleChange={formik.handleChange}
        error={touched.password && Boolean(errors.password)}
        helperText={errors.password}
      />
      <Button
        btnType="submit"
        btnContent="ENVOYER"
        primary
        handleClick={formik.handleSubmit}
      />
    </form>
  );
}

export default PasswordResetForm;
