import styles from './adCardContainer.module.scss';

function LoadErrorMessage() {
  return (
    <div>
      <p className={styles.errorContent}>
        Erreur de chargement des opportunités
        <br />
        Si le problème persiste, merci de nous contacter. <br />
        <br />
        <a
          href="mailto:support@urbanease.io"
          target="_blank"
          rel="nofollow noreferrer"
        >
          Support Prospec-Immo
        </a>
      </p>
    </div>
  );
}

export default LoadErrorMessage;
