import { nanoid } from '@reduxjs/toolkit';
import { memo } from 'react';
import { useHistory } from 'react-router-dom';
import { useAppDispatch } from '../../../App/redux/store';
import ActionCard from '../../../components/Assets/ActionCard';
import SectionTitle from '../../../components/Assets/sectionTitle';
import IndividualCircularLoader from '../../../components/Loader/IndividualCircularLoader';
import { activeResearchActions } from '../activeResearchSlice';
import useActiveResearches from '../useActiveResearches';
import RefreshActiveResearchesButton from './refreshActiveResearchesButton/RefreshActiveResearchesButton';

const ActiveResearchesDashboardBloc = memo(() => {
  const history = useHistory();
  const { activeResearches } = useActiveResearches({
    dashboard: true,
    loadActiveResearches: true,
  });
  const dispatch = useAppDispatch();

  const handleActiveReshearchCardClick = (elt: any) => {
    dispatch(activeResearchActions.activeResearchNewAdsCountReset(elt.idIri));
    history.push(`/recherche-active-resultat/${elt.id}`);
  };

  return (
    <section className="dashboard-section">
      <div className="dashboard-section-special-title">
        <SectionTitle
          mainTitle="Recherches actives"
          subTitle="Créez votre veille selon vos critères..."
        />
        <RefreshActiveResearchesButton />
      </div>

      {!activeResearches ? (
        <IndividualCircularLoader size={100} />
      ) : (
        <div className="dashboard-section-content">
          {activeResearches
            .filter((e) => e.totalNewAds > 0)
            .map((elt) => (
              <ActionCard
                key={nanoid()}
                cardType="active-search"
                cardTitle={elt.name}
                cardInfo={elt.totalAds ?? 0}
                cardUpdates={elt.totalNewAds ?? 0}
                cardState="filled"
                handleClick={() => handleActiveReshearchCardClick(elt)}
              />
            ))}
          <ActionCard
            cardTitle="Créez de nouveaux critères de recherche"
            cardState=""
            linkTo="/recherche-active-creation"
          />
        </div>
      )}
    </section>
  );
});

ActiveResearchesDashboardBloc.displayName = 'ActiveResearchesDashboardBloc';
export default ActiveResearchesDashboardBloc;
