import { Redirect } from 'react-router-dom';
import { useAppSelector } from '../App/redux/store';
import LeftBand from '../features/sign/leftBand';
import { getAuthState } from '../store/auth/reducer';
import './styles/MaintenancePage.scss';

const MaintenancePage = () => {
  const { maintenancePageOnly } = useAppSelector(getAuthState);

  //access only if maintenance value is on
  if (!maintenancePageOnly) {
    return <Redirect to={{ pathname: '/dashboard' }} />;
  }

  return (
    <div className="App">
      <div className="maintenance">
        <LeftBand />
        <main className="maintenance-content">
          <div className="maintenance-content-title">
            <h1>Maintenance en cours ...</h1>
            <p>Nous mettons à jour notre application.</p>
            <p>
              Début de la maintenance : <span>24/01/25, 17h00</span>
            </p>
            <p>
              Fin de la maintenance : <span>27/01/25, 8h00</span>
            </p>
            <p>
              Durée approximative : <span>48 heures.</span>
            </p>
            <p>Merci de votre compréhension.</p>
          </div>
        </main>
      </div>
    </div>
  );
};

export default MaintenancePage;
