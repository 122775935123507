import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import Header from './Header';
import Nav from './Nav';

import { isEmpty } from 'lodash-es';
import useAuth from '../App/hooks/useAuth';
import UserLoader from '../components/Loader/UserLoader';
import AdBlankPage from '../pages/AdBlankPage';
import ModalContainer from './ModalContainer';
import './styles/main.scss';

const Layout = ({ children }) => {
  const { user, maintenancePageOnly } = useAuth();

  const { pathname } = useLocation();
  const path = maintenancePageOnly ? '/maintenance' : pathname;

  switch (path) {
    case '/inscription':
    case '/recuperation-mot-de-passe':
    case '/premier-mot-de-passe':
    case '/erreur':
    case '/maintenance':
      return <main className="main">{children}</main>;

    default:
      return (
        <div className="body">
          {isEmpty(user) && <UserLoader />}
          <ModalContainer />
          {path.split('/')[1] === 'ad' ? (
            <AdBlankPage />
          ) : (
            <>
              <Header />
              <div className="body-content">
                <Nav activeNav={path} />
                <main className="main-content">{children}</main>
              </div>
            </>
          )}
        </div>
      );
  }
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
export default Layout;
