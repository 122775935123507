import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import useMarketStudies from '../../App/hooks/useMarketStudies';

import { marketStudiesActions } from '../../store/marketStudies/actions';
import { initialCreateValues, schema } from './form';

import { appManagerActions } from '../../store/appManager/actions';
import Button from '../Assets/Button';
import PromptCustom from '../Assets/PromptCustom';
import TextInput from '../Forms/TextInput';

import { isEmpty } from 'lodash-es';
import AutocompleteInputCities from '../Forms/AutocompleteInputCities';
import AutocompleteInputCollaborators from '../Forms/AutocompleteInputCollaborators';
import AutocompleteInputPropertyTypology from '../Forms/AutocompleteInputPropertyTypology';
import './MarketStudyForm.scss';

function MarketStudyForm() {
  const { id } = useParams();

  const { selectedMarketStudy } = useMarketStudies(id);

  const history = useHistory();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: isEmpty(selectedMarketStudy)
      ? initialCreateValues
      : selectedMarketStudy,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      dispatch(appManagerActions.formIsSubmitted.true());

      const filteredValues = { ...values };

      delete filteredValues.comment;
      const data = {
        body: filteredValues,
        history: history,
      };

      if (id) {
        dispatch(marketStudiesActions.updateMarketStudy.request(data));
      } else {
        dispatch(marketStudiesActions.createMarketStudy.request(data));
      }
    },
  });

  const { values, touched, errors, dirty } = formik;

  return (
    <>
      <PromptCustom when={dirty} />
      <form className="market-studies-create-form" noValidate>
        <div className="market-studies-create-form-inputs">
          <div className="market-studies-create-form-group">
            <TextInput
              label="Nom de l'étude"
              name="name"
              value={values.name}
              handleChange={formik.handleChange}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
            <AutocompleteInputCities
              value={values.city ?? []}
              handleChange={formik.setFieldValue}
              error={touched.city && Boolean(errors.city)}
              helperText={touched.city && errors.city}
            />
          </div>
          <div className="market-studies-create-form-group">
            <AutocompleteInputCollaborators
              value={values.collaborators ?? []}
              handleChange={formik.setFieldValue}
              multiple
            />
            <AutocompleteInputPropertyTypology
              name="propertyTypology"
              optionKey="name"
              value={values.propertyTypology}
              handleChange={formik.setFieldValue}
              idReducedPropertyTypologies
              error={Boolean(errors.propertyTypology)}
              helperText={errors.propertyTypology}
            />
          </div>
        </div>

        <div className="market-studies-create-form-btns">
          <Button
            btnType="submit"
            btnContent="GENERER L'ETUDE"
            primary
            handleClick={formik.handleSubmit}
          />
          <Button
            btnType="button"
            btnContent="ANNULER"
            handleClick={() => history.push('/etudes-de-marche')}
            textColor="violet"
            bgColor="white"
            borderColor="grey"
          />
        </div>
      </form>
    </>
  );
}

export default MarketStudyForm;
