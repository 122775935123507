import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { appManagerActions } from '../store/appManager/actions';
import { NotificationStatus } from '../utils/enums';
import { notificationTitles } from '../utils/genericNotifications';

import ActionCard from '../components/Assets/ActionCard';
import ActionNotification from '../components/Assets/ActionNotification';
import Button from '../components/Assets/Button';
import PaginationCustom from '../components/Assets/PaginationCustom';
import IndividualCircularLoader from '../components/Loader/IndividualCircularLoader';

import { nanoid } from '@reduxjs/toolkit';
import { useAppDispatch } from '../App/redux/store';
import SectionTitle from '../components/Assets/sectionTitle';
import ActiveResearchCard from '../features/activeResearch/components/ActiveResearchCard/ActiveResearchCard';

import RefreshActiveResearchesButton from '../features/activeResearch/components/refreshActiveResearchesButton/RefreshActiveResearchesButton';
import useActiveResearches from '../features/activeResearch/useActiveResearches';
import './styles/activeResearches.scss';

function ActiveResearchesPage() {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { activeResearches } = useActiveResearches({ loadActiveResearches: true });
  const [minIndexTab, setMinIndexTab] = useState(0);
  const display = 11;

  const handleCreateAdvancedSearch = () => {
    history.push('/recherche-active-creation');
  };

  useEffect(() => {
    if (activeResearches) {
      const activeResearchesNoCities = activeResearches.filter((elt: any) =>
        isEmpty(elt.cities)
      );
      if (!isEmpty(activeResearchesNoCities)) {
        dispatch(
          appManagerActions.actionNotificationDisplay.visible({
            title: notificationTitles.activeSearches.title,
            content:
              activeResearchesNoCities.length === 1
                ? `La recherche active "${activeResearchesNoCities[0].name}" n'a pas de ville, veuillez modifier la recherche active et rajouter une ville.`
                : `Les recherches actives ${activeResearchesNoCities
                    .map((e: any) => `"${e.name}"`)
                    .join(
                      ', '
                    )} n'ont pas de ville, veuillez modifier les recherches actives et rajouter une ville.`,
            status: NotificationStatus.FAILURE,
          })
        );
      }
    }
  }, [activeResearches]);

  return (
    <main className="active-search">
      <ActionNotification />
      <header className="active-search-header">
        <SectionTitle
          mainTitle="Recherches actives"
          subTitle="Créez vos propres critères de recherches"
        />
        <div className="active-search-header-buttons">
          <RefreshActiveResearchesButton />
          <Button
            btnType="button"
            btnContent="CREER UNE RECHERCHE"
            handleClick={handleCreateAdvancedSearch}
            primary
          />
        </div>
      </header>
      <section className="active-search-cards">
        {!activeResearches ? (
          <IndividualCircularLoader size={200} />
        ) : (
          <>
            {activeResearches
              .filter((elt: any, index: number) => {
                return index >= minIndexTab && index < minIndexTab + display;
              })
              .map((elt: any) => (
                <ActiveResearchCard key={nanoid()} activeResearch={elt} />
              ))}
            <ActionCard
              cardTitle="Créez une nouvelle recherche"
              cardState=""
              linkTo="/recherche-active-creation"
            />
          </>
        )}
      </section>
      {!activeResearches ? (
        <></>
      ) : (
        <PaginationCustom
          elementsQty={activeResearches.length}
          itemsPerPage={display}
          handleMinIndexTab={setMinIndexTab}
        />
      )}
    </main>
  );
}

export default ActiveResearchesPage;
