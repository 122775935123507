import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { comparativeStudiesActions } from '../../store/comparativeStudies/actions';
import { getComparativeStudiesState } from '../../store/comparativeStudies/reducer';
import { getPropertyTypologiesState } from '../../store/propertyTypologies/reducer';
import { useAppSelector } from '../redux/store';
import useAuth from './useAuth';

/**
 *
 * @param {*} id
 * @returns {object} {
    comparativeStudies,
    selectedComparativeStudy,
    comparativeStudyActive,
    comparativeStudyContent,
    deleteModal,
  }
 */
export default function useComparativeStudies(id, loadDetails) {
  const { user } = useAuth();
  const [selectedComparativeStudy, setSelectedComparativeStudy] = useState(null);
  const { propertyTypologies } = useAppSelector(getPropertyTypologiesState);
  const {
    comparativeStudies,
    comparativeStudyActive,
    comparativeStudyContent,
    deleteModal,
    comparisonsActiveTab,
  } = useSelector(getComparativeStudiesState);
  const dispatch = useDispatch();
  useEffect(() => {
    if (
      isEmpty(comparativeStudies) &&
      !isEmpty(propertyTypologies) &&
      !isEmpty(user)
    ) {
      dispatch(comparativeStudiesActions.fetchComparativeStudies.request());
    }
  }, [user, propertyTypologies]);

  useEffect(() => {
    if (loadDetails && comparativeStudyActive) {
      dispatch(comparativeStudiesActions.fetchActiveComparativeStudy.request());
    }
  }, [comparativeStudyActive, loadDetails]);

  useEffect(() => {
    if (!isEmpty(comparativeStudies) && id) {
      setSelectedComparativeStudy(comparativeStudies.find((elt) => elt.id === id));
    }
  }, [comparativeStudies, id]);

  return {
    comparativeStudies,
    selectedComparativeStudy,
    comparativeStudyActive,
    comparativeStudyContent,
    priceComparisons: comparativeStudyContent.priceComparisons ?? null,
    deleteModal,
    comparisonsActiveTab,
  };
}
