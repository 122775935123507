import { Tooltip } from '@material-ui/core';
import { forwardRef, LegacyRef, useState } from 'react';
import { ReactComponent as Deconnection } from '../../images/deconnection.svg';
import { ReactComponent as DeleteRed } from '../../images/delete-red.svg';
import { ReactComponent as DeleteWhite } from '../../images/delete-white.svg';
import { ReactComponent as PenViolet } from '../../images/pen-violet.svg';
import { ReactComponent as PenWhite } from '../../images/pen-white.svg';
import { ReactComponent as PictoPrinter } from '../../images/picto-printer.svg';
import './styles/Button.scss';

interface IButtonProps {
  btnType: 'button' | 'submit' | 'reset';
  btnContent?: React.ReactNode;
  btnImg?: string;
  handleClick?: () => void;
  disabled?: boolean;
  primary?: boolean;
  hasShadow?: boolean;
  textColor?: string;
  bgColor?: string;
  borderColor?: string;
  tooltipContent?: string;
}

const Button = forwardRef(
  (props: IButtonProps, ref: LegacyRef<HTMLButtonElement>) => {
    const {
      btnType,
      btnContent,
      btnImg,
      handleClick,
      textColor,
      bgColor,
      borderColor,
      disabled,
      primary,
      hasShadow,
      tooltipContent,
    } = props;
    const [showTooltip, setShowTooltip] = useState(false);

    let btnClass = 'button';
    if (primary) btnClass += ' button-primary';
    if (hasShadow) btnClass += ' button-shadow';

    if (btnContent) btnClass += ' button-text';
    if (!btnContent && btnImg) btnClass += ' button-svg';

    if (textColor === 'white') btnClass += ' button-text-white';
    if (textColor === 'violet') btnClass += ' button-text-violet';
    if (textColor === 'blue') btnClass += ' button-text-blue';
    if (textColor === 'darker-grey') btnClass += ' button-text-darker-grey';

    if (bgColor === 'violet') btnClass += ' button-bg-violet';
    if (bgColor === 'white') btnClass += ' button-bg-white';
    if (bgColor === 'blue') btnClass += ' button-bg-blue';
    if (bgColor === 'red') btnClass += ' button-bg-red';
    if (bgColor === 'grey') btnClass += ' button-bg-grey';
    if (bgColor === 'none') btnClass += ' button-bg-none';

    if (borderColor === 'grey') btnClass += ' button-border-grey';
    if (borderColor === 'violet') btnClass += ' button-border-violet';
    if (borderColor === 'white') btnClass += ' button-border-white';
    if (borderColor === 'darker-grey') btnClass += ' button-border-darker-grey';

    if (disabled) btnClass = 'button button-disabled';

    return (
      <Tooltip
        title={tooltipContent || ''}
        arrow
        placement="top"
        open={Boolean(showTooltip && disabled && tooltipContent) || false}
      >
        <button
          className={btnClass}
          type={btnType}
          onClick={handleClick}
          disabled={disabled}
          ref={ref}
          onMouseOver={() => setShowTooltip(true)}
          onMouseOut={() => setShowTooltip(false)}
        >
          {btnContent}
          {btnImg === 'deconnection' && <Deconnection />}
          {btnImg === 'delete-red' && <DeleteRed />}
          {btnImg === 'delete-white' && <DeleteWhite />}
          {btnImg === 'edit-violet' && <PenViolet />}
          {btnImg === 'edit-white' && <PenWhite />}
          {btnImg === 'printer' && <PictoPrinter />}
        </button>
      </Tooltip>
    );
  }
);

Button.displayName = 'Button';

export default Button;
