import { useEffect, useState } from 'react';
import IndividualCircularLoader from '../../../components/Loader/IndividualCircularLoader';
import styles from './imageWithTimeout.module.scss';
interface ImageWithTimeoutProps {
  src: string; // main image url
  fallback: string; // alternative image url
  timeout?: number; // timeout delay in ms
  alt?: string; // alternative text for this image
  className?: string; // custom css class
}

function ImageWithTimeout({
  src,
  fallback,
  timeout = 5000,
  alt = '',
  className = '',
}: ImageWithTimeoutProps) {
  const [isLoading, setIsLoading] = useState(true);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    // Start the timeout to monitor the image loading.
    const timer = setTimeout(() => {
      if (isLoading) {
        setHasError(true);
        setIsLoading(false);
      }
    }, timeout);

    // Clear the timeout if the image is loaded or fails beforehand.
    return () => clearTimeout(timer);
  }, [isLoading, timeout]);

  const handleLoad = () => {
    setIsLoading(false);
  };

  const handleError = () => {
    setHasError(true);
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && (
        <div className={styles.imageWithTimeoutLoader}>
          <IndividualCircularLoader size={100} className={styles.loader} />
        </div>
      )}
      <img
        className={[
          className,
          isLoading ? styles.imageHide : styles.imageDisplay,
        ].join(' ')}
        src={hasError ? fallback : src}
        onLoad={handleLoad}
        onError={handleError}
        alt={alt ? alt : ''}
      />
    </>
  );
}

export default ImageWithTimeout;
