import { createAsyncThunk } from '@reduxjs/toolkit';
import { RootState } from '../../../../App/redux/store';
import { fetchDatas } from '../../../../services/axiosInstanceService';
import { activeResearchesApiToStoreParser } from '../../activeResearchParser';
import fetchActiveResearchesTotalAdsThunk from './fetchActiveResearchesTotalAdsThunk';

const fetchActiveResearchesThunk = createAsyncThunk(
  'activeResearch/fetchActiveResearchesThunk',
  async (params: { loadAdsCount: boolean }, { dispatch, getState }) => {
    try {
      const state = getState() as RootState;
      const { userId } = state.auth;

      if (!userId) throw new Error('UserId not found');

      const result = await fetchDatas(`/users/${userId}/active-researches`);

      const parsed = activeResearchesApiToStoreParser(result['hydra:member']);

      if (params.loadAdsCount) {
        dispatch(
          fetchActiveResearchesTotalAdsThunk({ ids: parsed.map((m) => m.idIri) })
        );
      }

      return parsed;
    } catch (error) {
      return Promise.reject(error);
    }
  }
);

export default fetchActiveResearchesThunk;
