import { formatString } from '../../../../../utils/tools';

import AdCardBadge from './AdCardBadge';
import AdCardFooter from './AdCardFooter';
import AdCardHeader from './AdCardHeader';
import './adCard.scss';

interface IAdCardProps {
  ad: Ad;
  type: string;
  folderId?: string;
  status?: number;
  isNew?: boolean;
  checked: boolean;
  onCheckAd: () => void;
  onCardClick: () => void;
}

function AdCard({
  ad,
  type,
  status,
  isNew,
  onCheckAd,
  checked = false,
  onCardClick,
}: IAdCardProps) {
  const { propertyTypology, totalRoom } = ad;

  return (
    <div className="ad-thumb">
      <div>
        <AdCardHeader
          sellerType={ad.seller.type}
          status={type === 'adStudies' ? ad.status : null}
          ad={ad}
          isNew={isNew}
          onCheckAd={onCheckAd}
          checked={checked}
        />

        <section className="ad-thumb-main-section" onClick={onCardClick}>
          <AdCardBadge
            picture={
              ad.picture ?? process.env.PUBLIC_URL + '/images/logo-prospecimmo.svg'
            }
            status={ad.adStudy?.status ?? 0}
            hasBadge={type === 'adStudies'}
          />

          <div className="ad-thumb-header">
            <div className="ad-thumb-header-elt">
              <h4 className="ad-thumb-header-title">
                {`${propertyTypology.name} ${totalRoom > 0 ? 'F' + totalRoom : ''}`}
              </h4>
              <span className="ad-thumb-header-price">{`${ad.price} €`}</span>
            </div>
            <div className="ad-thumb-header-elt">
              <span className="ad-thumb-header-city">
                {formatString(ad.city.name)}
              </span>
              <span className="ad-thumb-header-meter-price">{`${ad.sqmPrice} €/m²`}</span>
            </div>
          </div>

          <ul className="ad-thumb-infos">
            <li className="ad-thumb-infos-item">{`Nbr de pièces : ${
              ad.totalRoom > 0 ? ad.totalRoom + ' Pièces' : '-'
            }`}</li>
            <li className="ad-thumb-infos-item">{`Superficie : ${ad.builtArea} m²`}</li>
            <li className="ad-thumb-infos-item">{`Superficie terrain : ${ad.landArea} m²`}</li>
          </ul>

          <AdCardFooter
            isBargain={ad.isBargain}
            isExclusive={ad.isExclusive}
            isGeolocatable={ad.isGeolocatable}
            url={ad.url}
            studied={ad.studied}
            isAdStudy={type === 'adStudies'}
            source={ad.source?.label ?? 'Indéfini'}
          />
        </section>
      </div>
    </div>
  );
}

export default AdCard;
