interface IRefreshIconProps {
  width?: number;
  height?: number;
  color?: string;
}

function RefreshIcon({
  color = '#FFF',
  height = 24,
  width = 24,
}: IRefreshIconProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width={width}
      height={height}
      fill={color}
      aria-hidden="true"
    >
      <path d="M17.65 6.35A7.95 7.95 0 0 0 12 4a8 8 0 1 0 8 8h-2a6 6 0 1 1-1.76-4.24l-1.9 1.9H20V4.41l-2.35 2.35z" />
    </svg>
  );
}

export default RefreshIcon;
