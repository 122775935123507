import { isArray, isEmpty } from 'lodash';
import {
  NEW_GOODS_SALE,
  NEW_GOODS_SOLD,
  OLD_GOODS_SALE,
  OLD_GOODS_SOLD,
} from '../../components/MarketStudyResult/constant';
import { cityApiToStore } from '../../utils/cityParser';
import { dateFormat } from '../../utils/jsFunctions';
import {
  nestedCollaboratorApiToStore,
  nestedCollaboratorsFormToApi,
} from '../collaborators/parser';
import { changeApiKeyForStoreKey, mapObjectParser } from '../common/parserCommon';
import { propertyTypologyApiToStoreParser } from '../propertyTypologies/parser';

const marketStudyResultMapping = {
  average_area: 'averageArea',
  period: 'period',
  prices: 'prices',
  property_category: 'propertyCategory',
  property_typology: 'propertyTypology',
  stat_category: 'statCategory',
  stocks: 'stocks',
  total_room: 'totalRoom',
};

const marketStudyResultPriceMapping = {
  avg_price: 'avgPrice',
  highest: 'highest',
  lowest: 'lowest',
  sqm_avg_price: 'sqmAvgPrice',
  variations: 'variations',
};

const marketStudyResultStocksMapping = {
  exploitable: 'exploitable',
  total: 'total',
  variations: 'variations',
};

const marketStudyResultPeriodMapping = {
  start: 'start',
  end: 'end',
};

const getPropertyTypology = (propertyTypology, propertyTypologies) => {
  if (!isArray(propertyTypology) || isEmpty(propertyTypologies)) return null;

  const value = propertyTypology[0];

  return propertyTypologies.find((f) =>
    typeof value === 'string' ? f.idIri === value : f.idIri === value['@id']
  );
};
const getOwner = (owner, users) => {
  if (!owner || isEmpty(users)) return null;

  const user = users.find((f) =>
    typeof owner === 'string' ? f.idIri === owner : f.idIri === owner['@id']
  );

  return user;
};
const getComment = (comment) => {
  let com = null;
  try {
    com = comment === null || comment === '' ? {} : JSON.parse(comment);
  } catch (error) {
    com = { comment_1: comment };
  }

  return com;
};

export const marketStudyApiToStore = (data, propertyTypologies, users) => {
  try {
    const collaborators = mapObjectParser(
      data.market_study_collaborators,
      nestedCollaboratorApiToStore
    );

    const city = cityApiToStore(data.city);

    const parsedData = {
      idIri: data['@id'],
      id: data['@id'].split('/')[2],
      collaborators,
      owner: getOwner(data.owner, users),
      comment: getComment(data.comment),
      propertyTypology: getPropertyTypology(
        data.property_typologies,
        propertyTypologies
      ),
      city,
      name: data.name,
      createdAt: data.created_at,
      updatedAt: data.updated_at,
    };

    return parsedData;
  } catch (error) {
    console.log('market studies parser', error);
  }
};
export const marketStudiesApiToStore = (datas, propertyTypologies, users) => {
  return datas.map((m) => marketStudyApiToStore(m, propertyTypologies, users));
};

export const marketStudyFormToApi = (data, isCommentUpdate) => {
  const parsedData = isCommentUpdate
    ? { comment: JSON.stringify(data.comment) }
    : {
        city: data.city.idIri,
        market_study_collaborators: nestedCollaboratorsFormToApi(data.collaborators),
        property_typologies: [data.propertyTypology.idIri],
        name: data.name,
      };

  return parsedData;
};

export const marketStudyResultApiToStore = (data) => {
  if (isEmpty(data)) {
    return [];
  }
  const parsedData = changeApiKeyForStoreKey(data, marketStudyResultMapping);

  parsedData.prices = changeApiKeyForStoreKey(
    data.prices,
    marketStudyResultPriceMapping
  );

  if (parsedData.period) {
    parsedData.period = changeApiKeyForStoreKey(
      data.period,
      marketStudyResultPeriodMapping
    );
  }

  parsedData.propertyTypology = propertyTypologyApiToStoreParser(
    data.property_typology
  );
  parsedData.stocks = changeApiKeyForStoreKey(
    data.stocks,
    marketStudyResultStocksMapping
  );

  return parsedData;
};

const pickupRoomData = (object, data, room) => {
  let stat = { oldSale: {}, oldSold: {}, newSale: {}, newSold: {} };
  stat.name = `${data[0].propertyTypology.name} F${room}`;

  ['old_property', 'new_property'].forEach((e) => {
    const cat = e === 'old_property' ? 'old' : 'new';

    ['sale', 'sold'].forEach((j) => {
      const state = j === 'sold' ? 'Sold' : 'Sale';
      let tempStat = {};

      const temp = data.find(
        (elt) =>
          elt.totalRoom === room &&
          elt.propertyCategory === e &&
          elt.statCategory === j
      );

      switch (cat + state) {
        case 'oldSale':
          tempStat.title = OLD_GOODS_SALE;
          break;
        case 'oldSold':
          {
            if (temp && temp.period && temp.period.start && temp.period.end) {
              tempStat.title =
                OLD_GOODS_SOLD +
                `${dateFormat(temp.period.start)} - ${dateFormat(temp.period.end)}`;
            } else {
              tempStat.title = OLD_GOODS_SOLD + ` - `;
            }
          }
          break;
        case 'newSale':
          tempStat.title = NEW_GOODS_SALE;
          break;
        case 'newSold':
          {
            if (temp && temp.period && temp.period.start && temp.period.end) {
              tempStat.title =
                NEW_GOODS_SOLD +
                `${dateFormat(temp.period.start)} - ${dateFormat(temp.period.end)}`;
            } else {
              tempStat.title = NEW_GOODS_SOLD + ` - `;
            }
          }
          break;
        default:
          break;
      }

      if (temp) {
        tempStat.totalStock = temp.stocks.total || 0;
        tempStat.exploitableStock = temp.stocks.exploitable || 0;
        tempStat.avgPrice = temp.prices.avgPrice || 0;
        tempStat.minPrice = temp.prices.lowest || 0;
        tempStat.maxPrice = temp.prices.highest || 0;
        tempStat.sqmAvgPrice = temp.prices.sqmAvgPrice || 0;
        tempStat.avgArea = temp.averageArea || 0;
      }

      stat[cat + state] = tempStat;
    });
  });
  object.goodsDatas.push(stat);
};

const pickupFieldData = (object, data, index) => {
  let stat = { oldSale: {}, oldSold: {} };
  if (data[index].propertyCategory === 'ground600')
    stat.name = `${data[0].propertyTypology.name} de 0 à 600 m²`;
  if (data[index].propertyCategory === 'ground1000')
    stat.name = `${data[0].propertyTypology.name} de 600 à 1000 m²`;
  if (data[index].propertyCategory === 'ground_more')
    stat.name = `${data[0].propertyTypology.name} supérieur à 1000 m²`;

  ['old_property'].forEach((e) => {
    const cat = e === 'old_property' ? 'old' : 'new';

    ['sale', 'sold'].forEach((j) => {
      const state = j === 'sold' ? 'Sold' : 'Sale';
      let tempStat = {};

      const temp = data.find(
        (elt) =>
          elt.propertyCategory === data[index].propertyCategory &&
          elt.statCategory === j
      );

      switch (cat + state) {
        case 'oldSale':
          tempStat.title = OLD_GOODS_SALE;
          break;
        case 'oldSold':
          {
            if (temp.period && temp.period.start && temp.period.end) {
              tempStat.title =
                OLD_GOODS_SOLD +
                `${dateFormat(temp.period.start)} - ${dateFormat(temp.period.end)}`;
            } else {
              tempStat.title = OLD_GOODS_SOLD + ` - `;
            }
          }
          break;
        default:
          break;
      }

      tempStat.totalStock = temp.stocks.total;
      tempStat.exploitableStock = temp.stocks.exploitable;
      tempStat.avgPrice = temp.prices.avgPrice;
      tempStat.minPrice = temp.prices.lowest;
      tempStat.maxPrice = temp.prices.highest;
      tempStat.sqmAvgPrice = temp.prices.sqmAvgPrice;
      tempStat.avgArea = temp.averageArea;

      stat[cat + state] = tempStat;
    });
  });
  object.goodsDatas.push(stat);
};

export const pdfDataParser = (data) => {
  let parsedData = {};
  parsedData.goodsDatas = [];
  if (data[0].totalRoom) {
    for (let i = 1; i <= 6; i += 1) {
      pickupRoomData(parsedData, data, i);
    }
  }
  if (data[0].propertyTypology.name === 'Terrain') {
    for (let i = 0; i <= 2; i += 1) {
      pickupFieldData(parsedData, data, i);
    }
  }

  parsedData.type = data[0].totalRoom ? data[0].totalRoom : data[0].propertyCategory;

  return parsedData;
};
