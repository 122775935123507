import Badge from '../../../../../components/badge';
import {
  BadgeBackgroundColorEnum,
  BadgeContentEnum,
  BadgeTextColorEnum,
} from '../../../../../components/badge/enums';
import ImageWithTimeout from '../../../../../shared/components/imageWithTimeout/ImageWithTimeout';
import './adCard.scss';
enum AdStudyStatusEnum {
  EN_COURS,
  VALIDE,
  ABANDONNE,
}
interface IAdCardBadgeProps {
  picture: string;
  status: AdStudyStatusEnum;
  hasBadge: boolean;
}

const AdCardBadge = ({ picture, status, hasBadge }: IAdCardBadgeProps) => {
  return (
    <div className="ad-thumb-cover">
      <ImageWithTimeout
        className="ad-thumb-cover-img"
        src={picture}
        alt=""
        fallback="/images/logo-prospecimmo.svg"
        timeout={5000}
      />

      {hasBadge && status === AdStudyStatusEnum.VALIDE && (
        <Badge
          badgeContent={BadgeContentEnum.VALIDE}
          bgColor={BadgeBackgroundColorEnum.GREEN}
          textColor={BadgeTextColorEnum.DARK_GREEN}
        />
      )}
      {hasBadge && status === AdStudyStatusEnum.EN_COURS && (
        <Badge
          badgeContent={BadgeContentEnum.EN_COURS}
          bgColor={BadgeBackgroundColorEnum.BLUE}
          textColor={BadgeTextColorEnum.DARK_BLUE}
        />
      )}
      {hasBadge && status === AdStudyStatusEnum.ABANDONNE && (
        <Badge
          badgeContent={BadgeContentEnum.ABANDONNE}
          bgColor={BadgeBackgroundColorEnum.RED}
          textColor={BadgeTextColorEnum.WHITE}
        />
      )}
    </div>
  );
};

export default AdCardBadge;
