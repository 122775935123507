import PasswordResetPage from '../components/PasswordResetPage/PasswordReset';
import Sign from '../features/sign';

import DashboardPage from '../pages/DashboardPage';

import ActiveResearchesPage from '../pages/ActiveResearchesPage';
import ActiveResearchFormPage from '../pages/ActiveResearchFormPage';
import ActiveResearchResultPage from '../pages/ActiveResearchResultPage';

import AdStudyPage from '../pages/AdStudyPage';
import MarketStudiesFormPage from '../pages/MarketStudiesFormPage';
import MarketStudiesPage from '../pages/MarketStudiesPage';
import MarketStudiesResultPage from '../pages/MarketStudiesResultPage';
import MyFoldersContentPage from '../pages/MyFolderContentPage';
import MyFoldersFormPage from '../pages/MyFoldersFormPage';
import MyFoldersPage from '../pages/MyFoldersPage';

import PieceworkPage from '../pages/PieceworkPage';

import ComparativeStudiesPage from '../pages/ComparativeStudiesPage';
import ComparativeStudyFormPage from '../pages/ComparativeStudyFormPage';

import AssistancePage from '../pages/AssistancePage';

import ErrorPage from '../pages/ErrorPage';

import AccountPAge from '../pages/AccountPage';

import { ReactComponent as NavDashboard } from '../images/nav-dashboard.svg';
import AdBlankPage from '../pages/AdBlankPage';
import MaintenancePage from '../pages/MaintenancePage';

const routes = [
  {
    path: '/',
    exact: true,
    title: 'Tableau de bord',
    Component: DashboardPage,
  },
  {
    path: '/maintenance',
    title: 'Maintenance',
    Component: MaintenancePage,
  },
  {
    path: '/inscription',
    exact: true,
    title: 'Inscription',
    Component: Sign,
  },
  {
    path: '/recuperation-mot-de-passe',
    exact: true,
    title: 'Changement de mot de passe',
    Component: PasswordResetPage,
  },
  {
    path: '/premier-mot-de-passe',
    exact: true,
    title: 'Premier de mot de passe',
    Component: PasswordResetPage,
  },
  {
    path: '/dashboard',
    nav: 1,
    navName: 'Dashboard',
    exact: true,
    title: 'Tableau de bord',
    Component: DashboardPage,
    Icon: NavDashboard,
  },
  {
    path: '/pige-classique',
    nav: 2,
    navName: 'Pige classique',
    exact: true,
    title: 'Pige classique',
    Component: PieceworkPage,
  },
  {
    path: '/recherches-actives',
    nav: 3,
    navName: 'Recherches actives',
    exact: true,
    title: 'Recherches actives',
    Component: ActiveResearchesPage,
  },
  {
    path: '/recherche-active-creation',
    exact: true,
    title: 'Recherche active - Créer',
    Component: ActiveResearchFormPage,
  },
  {
    path: '/recherche-active-modification/:id',
    exact: true,
    title: 'Recherche active - Modifier',
    Component: ActiveResearchFormPage,
  },
  {
    path: '/recherche-active-resultat/:id',
    exact: true,
    title: 'Recherche active - Résultat',
    Component: ActiveResearchResultPage,
  },
  {
    path: '/mes-dossiers',
    nav: 4,
    navName: 'Mes dossiers',
    exact: true,
    title: 'Mes dossiers',
    Component: MyFoldersPage,
  },
  {
    path: '/mes-dossiers-creation',
    exact: true,
    title: 'Mes dossiers - Création',
    Component: MyFoldersFormPage,
  },
  {
    path: '/details-dossier/:folderId',
    exact: true,
    title: 'Mes dossiers - Détails',
    Component: MyFoldersContentPage,
  },
  {
    path: '/creation-annonce-dossier/:folderId/:id',
    exact: true,
    title: 'Mes dossiers - Création Ad',
    Component: AdStudyPage,
  },
  {
    path: '/etudes-de-marche',
    nav: 5,
    navName: 'Etudes de marché',
    exact: true,
    title: 'Etudes de marché',
    Component: MarketStudiesPage,
  },
  {
    path: '/etudes-de-marche-creation',
    exact: true,
    title: 'Etudes de marché - Création',
    Component: MarketStudiesFormPage,
  },
  {
    path: '/etudes-de-marche-modification/:id',
    exact: true,
    title: 'Etudes de marché - Modification',
    Component: MarketStudiesFormPage,
  },
  {
    path: '/etude-de-marche-resultats/:id',
    exact: true,
    title: 'Etude de marché - Résultats',
    Component: MarketStudiesResultPage,
  },
  {
    path: '/etudes-comparatives',
    navName: 'Etudes comparatives',
    nav: 6,
    exact: true,
    title: 'Etudes comparatives',
    Component: ComparativeStudiesPage,
  },
  {
    path: '/etude-comparative-creation',
    exact: true,
    title: 'Etudes comparatives - Création',
    Component: ComparativeStudyFormPage,
  },
  {
    path: '/etude-comparative-modification/:id',
    exact: true,
    title: 'Etudes comparatives - Modification',
    Component: ComparativeStudyFormPage,
  },
  {
    path: '/assistance',
    nav: 7,
    navName: 'Assistance',
    exact: true,
    title: 'Assistance',
    Component: AssistancePage,
  },
  {
    path: '/mon-compte',
    nav: 9,
    navName: 'Mon compte',
    exact: true,
    title: 'Mon compte',
    Component: AccountPAge,
  },
  {
    path: '/ad/:id',
    exact: true,
    title: 'ad-detail',
    Component: AdBlankPage,
  },
  {
    path: '/erreur',
    title: 'Erreur',
    Component: ErrorPage,
  },
];

export default routes;
