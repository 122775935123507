import SessionStorageService from '../../services/sessionStorageService';
import { NotificationStatus } from '../../utils/enums';
import { LOGOUT } from '../auth/actions';
import {
  FALSE,
  HIDDEN,
  RESET,
  SET,
  SUCCESS,
  TRUE,
  VISIBLE,
} from '../common/actions';
import {
  ACTION_NOTIFICATION_DISPLAY,
  FORM_IS_SUBMITTED,
  SELECTED_ACCOUNT_TAB,
  SELECTED_ASSISTANCE_TAB,
} from './actions';

const initialState = {
  actionNotification: {
    display: false,
    title: '',
    content: '',
    link: '',
    contentLink: '',
    status: NotificationStatus.NONE,
  },
  formIsSubmitted: false,
  selectedAccountTab: SessionStorageService.getAccountTab() ?? 0,
  selectedAssistanceTab: SessionStorageService.getAssistanceTab() ?? 0,
};

const actionNotificationReducer = (state, action) => {
  switch (action.type) {
    case ACTION_NOTIFICATION_DISPLAY[VISIBLE]:
      return {
        ...state,
        display: true,
        title: action.payload.title,
        content: action.payload.content,
        link: action.payload.link,
        contentLink: action.payload.contentLink,
        status: action.payload.status,
      };
    case ACTION_NOTIFICATION_DISPLAY[HIDDEN]:
      return {
        ...state,
        display: false,
        title: '',
        content: '',
        link: '',
        contentLink: '',
        status: NotificationStatus.NONE,
      };

    default:
      return state;
  }
};

export default (state = initialState, action) => {
  switch (action.type) {
    case ACTION_NOTIFICATION_DISPLAY[VISIBLE]:
      return {
        ...state,
        actionNotification: actionNotificationReducer(
          state.actionNotification,
          action
        ),
      };
    case ACTION_NOTIFICATION_DISPLAY[HIDDEN]:
      return {
        ...state,
        actionNotification: actionNotificationReducer(
          state.actionNotification,
          action
        ),
      };
    case SELECTED_ACCOUNT_TAB[SET]:
      return { ...state, selectedAccountTab: action.payload };
    case SELECTED_ACCOUNT_TAB[RESET]:
      return { ...state, selectedAccountTab: 0 };

    case SELECTED_ASSISTANCE_TAB[SET]:
      return { ...state, selectedAssistanceTab: action.payload };
    case SELECTED_ASSISTANCE_TAB[RESET]:
      return { ...state, selectedAssistanceTab: 0 };

    case FORM_IS_SUBMITTED[TRUE]:
      return { ...state, formIsSubmitted: true };
    case FORM_IS_SUBMITTED[FALSE]:
      return { ...state, formIsSubmitted: false };
    case LOGOUT[SUCCESS]:
      return { ...initialState, selectedAccountTab: 0, selectedAssistanceTab: 0 };
    default:
      return state;
  }
};

export const getAppManagerState = (state) => state.appManager;
export const getActionNotificationDisplayState = (state) =>
  state.appManager.actionNotification.display;
export const getActionNotificationState = (state) =>
  state.appManager.actionNotification;
export const getFormIsSubmittedState = (state) => state.appManager.formIsSubmitted;
export const getSelectedAccountTabState = (state) =>
  state.appManager.selectedAccountTab;
