import { Route, Switch, useHistory } from 'react-router-dom';

import HelmetCustom from '../components/Assets/HelmetCustom';
import Loader from '../components/Assets/Loader';
import PasswordResetPage from '../components/PasswordResetPage/PasswordReset';
import Sign from '../features/sign';

import Layout from '../layout';
import AuthRoutes from '../router/AuthRoutes';

import { useEffect } from 'react';
import MaintenancePage from '../pages/MaintenancePage';
import { getAuthState } from '../store/auth/reducer';
import './app.scss';
import { useAppSelector } from './redux/store';

function App() {
  const { maintenancePageOnly } = useAppSelector(getAuthState);
  const history = useHistory();

  useEffect(() => {
    if (maintenancePageOnly) {
      history.push('/maintenance');
    }
  }, [maintenancePageOnly]);

  return (
    <div className="app">
      <HelmetCustom />
      <Loader />
      <Layout>
        <Switch>
          {maintenancePageOnly ? (
            <Route path="*" component={MaintenancePage} />
          ) : (
            <>
              <Route path="/inscription" component={Sign} />
              <Route
                path="/recuperation-mot-de-passe"
                component={PasswordResetPage}
              />
              <Route path="/premier-mot-de-passe" component={PasswordResetPage} />
              <AuthRoutes />
            </>
          )}
        </Switch>
      </Layout>
    </div>
  );
}

export default App;
