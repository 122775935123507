import { useHistory } from 'react-router-dom';
import { ReactComponent as FolderBlueUser } from '../../images/folder-blue-user.svg';
import { ReactComponent as FolderBlue } from '../../images/folder-blue.svg';
import { ReactComponent as MyFolderViolet } from '../../images/my-folder-violet.svg';
import { IFolderLineProps } from './FolderLine';

interface IFolderLineIconProps extends IFolderLineProps {
  isActive: boolean;
}
const FolderLineIcon = ({ folder, isActive }: IFolderLineIconProps) => {
  const history = useHistory();

  if (!folder) return null;

  return (
    <div className="my-folders-title">
      {folder?.isDefault ? (
        <MyFolderViolet />
      ) : folder?.collaborators?.length > 0 ? (
        <FolderBlueUser className="my-folders-picto-folder" />
      ) : (
        <FolderBlue className="my-folders-picto-folder" />
      )}
      <span
        className={isActive ? 'folder-name-selected' : ''}
        onClick={() => history.push(`/details-dossier/${folder?.id}`)}
      >
        {folder?.name}
      </span>
    </div>
  );
};

export default FolderLineIcon;
