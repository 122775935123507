import { combineReducers } from 'redux';
import activeResearchReducer from '../../features/activeResearch/activeResearchSlice';
import adReducer from '../../features/ad/adSlice';
import pieceworkReducer from '../../features/piecework/pieceworkSlice';
import procedureEvents from '../../features/procedureEvent/reducer';
import account from '../../store/account/reducer';
import adStudies from '../../store/adStudies/reducer';
import announcementsReducer from '../../store/announcements/reducer';
import appManager from '../../store/appManager/reducer';
import auth from '../../store/auth/reducer';
import collaborators from '../../store/collaborators/reducer';
import comparativeStudies from '../../store/comparativeStudies/reducer';
import comparativeStudyComparisons from '../../store/comparativeStudyComparisons/reducer';
import comparativeStudyComparisonsSelect from '../../store/comparativeStudyComparisonsSelect/reducer';
import folders from '../../store/folders/reducer';
import loader from '../../store/loader/reducer';
import marketStudies from '../../store/marketStudies/reducer';
import modals from '../../store/modals/reducer';
import propertyTypologies from '../../store/propertyTypologies/reducer';
import subscription from '../../store/subscription/reducer';

const rootReducer = combineReducers({
  //redux toolkit reducers
  activeResearch: activeResearchReducer,
  ad: adReducer,
  piecework: pieceworkReducer,
  // classic reducers
  modals,
  account,
  comparativeStudies,
  comparativeStudyComparisons,
  comparativeStudyComparisonsSelect,
  folders,
  marketStudies,
  auth,
  appManager,
  loader,
  collaborators,
  propertyTypologies,
  adStudies,
  subscription,
  procedureEvents,
  announcementsReducer,
});

export default rootReducer;
