import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../App/redux/store';
import { APIStatus } from '../../services/axiosEnums';
import { getActiveResearchState } from './activeResearchSlice';
import fetchActiveResearchesThunk from './services/thunks/fetchActiveResearchesThunk';
import fetchCurrentActiveResearchThunk from './services/thunks/fetchCurrentActiveResearchThunk';

const FETCH_TOTAL_ADS_TIMER = 15 * 60 * 1000;

const shouldFetchData = (): boolean => {
  const lastFetchTimestamp = sessionStorage.getItem('lastNewAdsFetchTimestamp');
  if (!lastFetchTimestamp) {
    const cur = Date.now();
    sessionStorage.setItem('lastNewAdsFetchTimestamp', cur.toString());
    return true;
  }
  const currentTime = Date.now();
  const elapsedTime = currentTime - parseInt(lastFetchTimestamp, 10);

  const canFetch = elapsedTime > FETCH_TOTAL_ADS_TIMER;

  if (canFetch) {
    sessionStorage.setItem('lastNewAdsFetchTimestamp', currentTime.toString());
    return true;
  }

  return false;
};

export default function useActiveResearches({
  activeResearchId,
  loadActiveResearches,
}: {
  activeResearchId?: string | null;
  dashboard?: boolean;
  loadActiveResearches?: boolean;
}) {
  const { activeResearches, currentActiveResearch } = useAppSelector(
    getActiveResearchState
  );
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      loadActiveResearches ||
      (!activeResearches.result && activeResearches.apiStatus === APIStatus.IDLE)
    ) {
      const loadAdsCount = shouldFetchData();
      dispatch(
        fetchActiveResearchesThunk({
          loadAdsCount: loadAdsCount || !activeResearches.result,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (
      activeResearchId &&
      currentActiveResearch.apiStatus === APIStatus.IDLE &&
      !currentActiveResearch.result
    ) {
      dispatch(fetchCurrentActiveResearchThunk({ id: activeResearchId }));
    }
  }, [
    activeResearchId,
    currentActiveResearch.apiStatus,
    currentActiveResearch.result,
  ]);

  return {
    activeResearches: activeResearches.result,
    currentActiveResearch: currentActiveResearch.result,
    isCurrentActiveResearchLoading:
      currentActiveResearch.apiStatus === APIStatus.PENDING,
  };
}
