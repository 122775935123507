import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { marketStudiesActions } from '../../store/marketStudies/actions';
import Button from '../Assets/Button';
import TextInput from '../Forms/TextInput';

interface IMarketStudyResultCommentProps {
  name: string;
  activeMarketStudy: any;
}
function MarketStudyResultComment({
  name,
  activeMarketStudy,
}: IMarketStudyResultCommentProps) {
  const [commentState, setCommentState] = useState('');
  const dispatch = useDispatch();

  useEffect(() => {
    if (name && commentState === '' && activeMarketStudy) {
      setCommentState(activeMarketStudy.comment[name]);
    }
  }, [activeMarketStudy, name]);

  const handleSubmit = () => {
    const newComment = { ...activeMarketStudy.comment, [name]: commentState };
    dispatch(
      marketStudiesActions.updateMarketStudy.request({
        body: { idIri: activeMarketStudy.idIri, comment: newComment },
        isCommentUpdate: true,
      })
    );
  };

  const handleChange = (e: any) => {
    setCommentState(e.target.value);
  };
  const handleBlur = () => {
    const ms = {
      ...activeMarketStudy,
      comment: {
        ...activeMarketStudy.comment,
        [name]: commentState,
      },
    };
    dispatch(marketStudiesActions.updateMarketStudy.success(ms));
  };

  return (
    <>
      <h4 className="market-studies-result-section-title">Commentaires</h4>
      <form>
        <TextInput
          label="Remarques diverses"
          rows={12}
          name={name}
          value={commentState}
          handleChange={handleChange}
          onBlur={handleBlur}
        />
        <Button
          btnType="button"
          btnContent="ENREGISTRER"
          handleClick={handleSubmit}
          bgColor="none"
          borderColor="darker-grey"
          textColor="darker-grey"
        />
      </form>
    </>
  );
}

export default MarketStudyResultComment;
