import { isEmpty } from 'lodash';
import { useEffect } from 'react';
import { getAuthState } from '../../store/auth/reducer';
import { collaboratorsActions } from '../../store/collaborators/actions';
import { propertyTypologyActions } from '../../store/propertyTypologies/actions';
import { useAppDispatch, useAppSelector } from '../redux/store';

function useInitApp() {
  const dispatch = useAppDispatch();
  const { isLogged, user } = useAppSelector(getAuthState);

  useEffect(() => {
    if (isLogged && !isEmpty(user)) {
      dispatch(propertyTypologyActions.fetchPropertyTypologies.request());
      dispatch(collaboratorsActions.fetchCollaborators.request());
    }
  }, [isLogged, user]);
}

export default useInitApp;
